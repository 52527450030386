var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"40669a74b4c4760ee8a94cfeb6b8adeee89a61d4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import * as SentryBrowser from "@sentry/browser";
import { isExceptionTrackingRequired } from "./src/libs/ErrorReporter";

export class EventTrackerInitialiser {
  initialise() {
    if (isExceptionTrackingRequired()) {
      console.log("SENTRY INITIALISED");
      Sentry.init({
        dsn: `${process.env.NEXT_PUBLIC_SENTRY_DSN}`,
        tracesSampleRate: 1.0,
        debug: true,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
          new SentryBrowser.BrowserTracing(),
          new SentryBrowser.Breadcrumbs(),
        ],
      });
      console.log("SENTRY INITIALISED");
    }
  }
}
